import axios from 'axios';
import { getToken, clearForLogout } from '@/utils/auth';
import store from '@/store';
import router from '@/router';
import { errorsAlert, netwokErrorAlert } from './requestAlerts';

const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, 
  timeout: 300000 // request timeout
});
service.interceptors.request.use(
  config => {
    loaderIncrease(config);
    let token = getToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    config.headers['X-USER-LANG'] = 'ru';
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  response => {
    loaderDecrease(response.config);
    const res = response.data;
    if (response.status !== 200) {
      return Promise.reject(new Error(res.message || 'Error'));
    } else {
      return res;
    }
  },
  error => {
    loaderDecrease(error.config);
    if(error.response){
      errorsAlert(error.response);
    }else{
      netwokErrorAlert(error);
    }
    if (error.response.status == 401) {
      logout();
    }
    return Promise.reject(error.response);
  }
);

function logout() {
  clearForLogout();
  return router.push({ name: 'Login' });
}


const notLoader = [
  '/invoice-statistics',
];
function loaderIncrease(config) {
  if (notLoader.indexOf(config['url']) < 0) {
    store.dispatch('loader/increase');
  }
}
function loaderDecrease(config) {
  if (notLoader.indexOf(config['url']) < 0) {
    store.dispatch('loader/decrease');
  }
}

export default service;
