<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { getToken, checkTokenTime } from "@/utils/auth";
export default {
  computed: {},
  watch: {},
  created() {
    this.cacheClear()
    this.checkAuth();
  },
  methods: {
    ...mapActions({
      // getFormOptions: 'classifiers/getFormOptions',
      getCurrentUser: 'auth/currentUser',
    }),
    cacheClear(){
      if(caches){
        caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
        console.log('cache deleted')
      }
    },
    checkAuth(){
      let token = getToken();
      if(token && checkTokenTime()){
        this.getCurrentUser();
        // NotificationsSubscriber(this.currentUser)
      }
    }
  }
};
</script>
<style lang="scss">
@import './styles.scss';
@import './assets/styles/media.scss';
</style>
