import request from '@/utils/request';

export function invoices(params) {
  return request({
    url: '/didox/invoices',
    method: 'get',
    params
  });
}

export function companies(params) {
  return request({
    url: '/didox/companies',
    method: 'get',
    params
  });
}

export function statistics(params) {
  return request({
    url: '/didox/invoice-statistics',
    method: 'get',
    params
  });
}