export function firstCharUppercase(string) {
  if (typeof string == 'string' && string.length > 1) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
}

export function formatPrice(value, decimal = 0) {
  const val = (value / 1).toFixed(decimal).replace(' ', ',');
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function upper(value) {
  return String(value).toUpperCase();
}

export function formatDate(value, format = 'DD.MM.YYYY HH:mm:ss') {
  let dateClass = value != "" ? new Date(value) : new Date();
  if (format.search("YYYY") >= 0) {
    format = format.replace("YYYY", String(dateClass.getFullYear()));
  }
  if (format.search("MM") >= 0) {
    let monthInt = dateClass.getMonth() + 1;
    let month = monthInt > 9 ? monthInt : ("0" + monthInt);
    format = format.replace("MM", String(month));
  }
  if (format.search("DD") >= 0) {
    let day = dateClass.getDate() > 9 ? dateClass.getDate() : ("0" + dateClass.getDate());
    format = format.replace("DD", String(day));
  }
  if (format.search("HH") >= 0) {
    let hours = dateClass.getHours() > 9 ? dateClass.getHours() : ("0" + dateClass.getHours());
    format = format.replace("HH", String(hours));
  }
  if (format.search("mm") >= 0) {
    let minutes = dateClass.getMinutes() > 9 ? dateClass.getMinutes() : ("0" + dateClass.getMinutes());
    format = format.replace("mm", String(minutes));
  }
  if (format.search("ss") >= 0) {
    let seconds = dateClass.getSeconds() > 9 ? dateClass.getSeconds() : ("0" + dateClass.getSeconds());
    format = format.replace("ss", String(seconds));
  }
  if (format.search("MONTH") >= 0) {
    let monthInt = dateClass.getMonth();
    let monthName = monthsRu[monthInt];
    format = format.replace("MONTH", monthName);
  }
  return format;
}
const monthsRu = {
  0: "Январь",
  1: "Февраль",
  2: "Март",
  3: "Апрель",
  4: "Май",
  5: "Июнь",
  6: "Июль",
  7: "Август",
  8: "Сентябрь",
  9: "Октябрь",
  10: "Ноябрь",
  11: "Декабрь",
};