import store from '@/store';
export const mutations = {
  SET_LIST: (state, data) => {
    state.list = data['data'];
    state.pagination = {
      total: data['total'],
      last_page: data['last_page'],
      page: data['current_page'],
      limit: data['per_page'],
      startIndex: ((data['current_page']-1)*data['per_page']),
    }
  },
  SET_MODEL: (state, data) => {
    state.model = setWorkerCounts(data)
  },
};

function setWorkerCounts(data){
  if(data && data.worker_count){
    let workerCounts = store.getters['classifiers/WORKER_COUNTS']
    let find = workerCounts.find(item => data.worker_count == item.id);
    if(find){
      data['workerCount'] = find
    }
  }
  return data;
}