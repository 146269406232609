import request from '@/utils/request';

export function classifiers(table, params) {
  return request({
    url: '/classifiers/'+table,
    method: 'get',
    params: params
  });
}
export function formOptions(data) {
  return request({
    url: '/form-options/list',
    method: 'get',
    params: data
  });
}