export const mutations = {
  SET_INVOICES: (state, data) => {
    state.invoices = data['data'];
    state.invoices_pagination = {
      total: data['total'],
      last_page: data['last_page'],
      page: data['current_page'],
      limit: data['per_page'],
      startIndex: (data['total']-(data['per_page']*(data['current_page']-1))),
    }
  },
  SET_COMPANIES: (state, data) => {
    state.companies = data['data'];
    state.companies_pagination = {
      total: data['total'],
      last_page: data['last_page'],
      page: data['current_page'],
      limit: data['per_page'],
      startIndex: ((data['current_page']-1)*data['per_page']),
    }
  },
  SET_STATISTICS: (state, data) => {
    state.statistics = data;
  },
};