import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from './auth';
import loader from './loader';
import classifiers from './classifiers';
import statistics from './statistics'
import companies from './companies'
import invoices from './invoices'
import users from './users'
import plans from './plans'
import didox from './didox'

const store = new Vuex.Store({
  modules: {
    auth,
    loader,
    classifiers,
    statistics,
    companies,
    invoices,
    users,
    plans,
    didox
  }
});

export default store;

const initialStateCopy = JSON.parse(JSON.stringify(store.state));

export function resetState() {
  let storeData = JSON.parse(JSON.stringify(store.state));
  initialStateCopy['auth']['is_auth'] = false;
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}