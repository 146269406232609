import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/ElementUi'
import './mixins';
import './components';

import { formatDate, firstCharUppercase, formatPrice, upper } from "./filters";
Vue.filter("first-ch-upper", firstCharUppercase);
Vue.filter("price", formatPrice);
Vue.filter("upper", upper);
Vue.filter('date', formatDate);

import LoaderLogo from '@/components/loaders/logo';
Vue.component('v-loader-logo', LoaderLogo);

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

import FormOption from '@/components/elements/FormOption';
Vue.component('form-option', FormOption);

import ClassifierName from '@/components/elements/ClassifierName';
Vue.component('classifier-name', ClassifierName);

import DropdownDatepicker from '@/components/dropdown-datepicker/dropdown-datepicker';
Vue.component("dropdown-datepicker", DropdownDatepicker);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
