import request from '@/utils/request';

export function list(params) {
  return request({
    url: '/invoices',
    method: 'get',
    params
  });
}
export function show(uuid) {
  return request({
    url: '/invoices/show/'+uuid,
    method: 'get',
  });
}
export function paymentVerify(data) {
  return request({
    url: '/payment-verify/'+data['uuid'],
    method: 'post',
    data: data
  });
}