import request from '@/utils/request';

export function list(params) {
  return request({
    url: '/unijobs/companies',
    method: 'get',
    params
  });
}
export function show(id) {
  return request({
    url: '/unijobs/companies/'+id,
    method: 'get',
  });
}
export function update(data) {
  const formData = new FormData();
  formData.append('_method', 'PUT');
  Object.keys(data).forEach(key => {
    if(Array.isArray(data[key])){
      data[key].forEach(item => {
        formData.append(`${key}[]`, item);
      })
    }else{
      formData.append(key, data[key]);
    }
  })
  return request({
    headers: {'Content-Type':'multipart/form-data'},
    url: '/unijobs/companies/'+data['id'],
    method: 'post',
    data: formData
  });
}
export function deactivate(id) {
  return request({
    url: '/unijobs/companies/deactivate/'+id,
    method: 'put',
  });
}