import { statistics } from '@/api/statistics';

export const actions = {
  getStatistics({ commit }) {
    return new Promise((resolve, reject) => {
      statistics()
        .then(res => {
          if (res['success'] && res['data']) {
            commit('SET_STAT', res['data']);
          }
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
