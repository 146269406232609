import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/";
import middlewares from "./middlewares";
import { checkTokenTime } from "@/utils/auth";

Vue.use(VueRouter)

var modules = [];

const routes = [
  {
    path: "/",
    name: "Main",
    component: () =>
      import("@/layouts/Main"),
    children: [{
      path: "/dashboard",
      name: "Dashboard",
      redirect: { name: "Invoices" },
      meta: { middleware: 'AuthAccountant' },
      component: () =>
        import("@/views/Dashboard/Index"),
      children: [{
        path: "invoices",
        name: "Invoices",
        meta: { middleware: 'AuthAccountant' },
        component: () =>
          import("@/views/Invoices/Index"),
      },{
        path: "companies",
        name: "Companies",
        meta: { middleware: 'AuthAccountant' },
        component: () =>
          import("@/views/Companies/Index"),

      },{
        path: "users",
        name: "Users",
        meta: { middleware: 'AuthAccountant' },
        component: () =>
          import("@/views/Users/Index"),

      }],
    },{
      path: "/invoices/:uuid",
      name: "InvoicesShow",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/Invoices/Show"),
    },{
      path: "/didox/invoices",
      name: "DidoxInvoices",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/Didox/Index"),
    },{
      path: "/didox/companies",
      name: "DidoxCompanies",
      meta: { middleware: 'Auth' },
      component: () =>
        import("@/views/Didox/Companies"),
    }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import("@/views/Auth/Login"),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.middleware) {
    if (middlewares[to.meta.middleware]) {
      let $res = middlewares[to.meta.middleware](store);
      if (!$res || !checkTokenTime()) {
        return next({ name: "Login" });
      }
    }
  }
  return next();
});

export default router
